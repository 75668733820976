@use '../Config/variables.scss' as *;
@use '../Config/mixins.scss' as *;
@use 'sass:map';

.container {
  padding: 50px 0;
  width: 100%;
}

.row {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 12px;
  display: flex;
}

.flex-row {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn {
  background-color: lightgray;
}

.col {
  display: flex;
  align-items: center;
  flex-direction: column;

  .title {
    text-align: center;
  }
}

.start {
  align-items: flex-start;
}

.primcolor {
  color: map.get($colors, primary-1);
}

.primcolor-bg {
  background-color: map.get($colors, primary-1);
  color: white;
}

.btn {
  height: 56px;
  padding: 8px 30px;
  border: none;
  border-radius: 10px;
  @include flexCentered;
  transition: all map.get($transition-durations, 3) ease;
  text-decoration: none;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: bolder;
  font-size: 16px;
  margin-top: 20px;
}

.btn--arrow p {
  padding-right: 10px;
}

.click:hover {
  cursor: pointer;
  transform: translateY(-2%);
}

.section-line {
  height: 3px;
  width: 40px;
  background-color: map.get($colors, primary-1);
  margin-bottom: 24px;
}

.skeleton-box {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #dddbdd;
}

.skeleton-box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0)
  );
  -webkit-animation: shimmer 2s infinite;
  animation: shimmer 2s infinite;
  content: '';
}
@-webkit-keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.title {
  font-size: 55px;
  margin-bottom: 15px;
  font-family: 'SUSE', sans-serif;
}

.sub-title,
.para {
  font-size: 18px;
  margin-bottom: 24px;
  font-weight: 400;
  color: map.get($colors, para);
}

.para {
  line-height: 32px;
}

.right,
.left {
  width: 50%;
  @include flexCollCenterVert;
}

.left:has(.img--wrapper),
.right:has(.img--wrapper) {
  @include flexCentered;
}

.img--wrapper {
  width: 70%;
  @include flexCentered;

  img {
    width: 100%;
  }
}

/*

HEADER/NAV

*/
nav {
  padding: 0 10px;
  position: fixed;
  left: 20px;
  right: 20px;
  top: 10px;
  border-radius: 12px;
  border: 2px solid rgba(0, 0, 0, 0.059);
  box-shadow: 0 0 0px rgba(0, 0, 0, 0.057);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  height: 70px;

  transition: all map.get($transition-durations, 3) ease;
  z-index: 200;
  @include flexCentered;

  .row {
    @include flexCenterVert;
    max-width: 1300px;

    transition: all 300ms ease;
  }
  .container {
    padding: 12px 0;
  }

  .btn {
    height: 48px;
    padding: 2px 16px;
  }
}

.hidden-nav {
  top: -100px;
}

.scrolled,
.white-nav,
nav:hover,
#root:has(.sidebar-open) nav {
  background-color: map.get($colors, white);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.057);

  .sidebar--btn {
    color: black;
  }

  .header__link {
    a {
      color: rgb(70, 70, 70);
    }
  }
}

.header--left,
.header--right {
  width: 50%;
  height: 62px;
  @include flexCenterVert;
}

.header--right {
  justify-content: flex-end;

  .btn {
    margin: 0;
  }
}

.header--links {
  display: flex;
  list-style-type: none;
}

.header__link {
  margin-left: 40px;
  a {
    color: white;
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;

    transition: color 300ms ease;
  }

  a:hover {
    color: black !important;
  }
}

.header__logo--wrapper {
  width: 160px;
  display: flex;
  transition: all 300ms ease;
}

.header__logo {
  width: 100%;
  display: flex;
}

.sidebar--btn {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 32px;
  @include flexCenteredCollumn;

  cursor: pointer;

  display: none;
}

.sidebar {
  position: fixed;
  top: 0;
  background-color: white;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;

  @include flexCenteredCollumn;
  display: none;
}

.sidebar-open {
  display: flex;
}

.sidebar--links {
  list-style-type: none;
  @include flexCenteredCollumn;
}

.sidebar__link {
  a {
    color: black;
    text-decoration: none;
    font-size: 28px;
  }

  margin: 24px 0;
}

.scroll {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
}

.scroll__icon {
  width: 20px;
  height: 30px;
  border: 2px solid map.get($colors, white);
  border-radius: 24px;
  @include flexCentered;
}

.scroll__icon:after {
  content: '';
  width: 4px;
  height: 6px;
  background-color: map.get($colors, white);
  border-radius: 2px;
  animation: 1s infinite alternate-reverse scroll ease-in-out;
}
@keyframes scroll {
  0% {
    transform: translateY(3px);
  }
  100% {
    transform: translateY(-3px);
  }
}

footer {
  background-color: #1c1a1e;
  color: white;

  @include flexCenteredCollumn;

  .row {
    display: grid;
    grid-template-columns: auto auto auto auto;
  }
}

.footer--section {
  @include flexCollumn;
  padding: 0 70px;
  margin-bottom: 40px;

  .footer--title {
    font-size: 20px;
    padding-bottom: 24px;
  }

  ul {
    list-style-type: none;
  }
}

.footer--link {
  margin-bottom: 24px;

  button {
    border: none;
    color: white;
    font-size: 16px;
    background: transparent;
    cursor: pointer;
  }

  button,
  a {
    color: white;
    font-size: 16px;
    text-decoration: none;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: -4px;
      right: 0;
      height: 2px;
      width: 0;
      background-color: white;
      transition: all 300ms ease;
    }

    &:hover {
      &::after {
        left: 0;
        width: 100%;
      }
    }
  }
}

.footer__img--wrapper {
  width: 150px;
  img {
    width: 100%;
  }
  margin-bottom: 20px;
}

.footer--info,
.footer--para {
  font-size: 14px;
  margin-bottom: 20px;

  .footer__info--top {
    margin-bottom: 4px;
  }

  a {
    color: white;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.footer__copyright--container {
  font-size: 12px;
  display: flex;
  margin-top: 20px;

  a {
    text-wrap: nowrap;
    padding-right: 12px;
    border-right: 1px solid rgb(177, 178, 193);
    color: white;
    text-decoration: none;
  }

  p {
    padding: 0 12px;
    max-width: 170px;
  }
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 65px;
  aspect-ratio: 1;
  position: relative;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  border-radius: 50px;
  box-shadow: 0 0 0 3px inset #fff;
  animation: l4 2.5s infinite;
}
.loader:after {
  animation-delay: -1.25s;
}
@keyframes l4 {
  0% {
    inset: 0 35px 35px 0;
  }
  12.5% {
    inset: 0 35px 0 0;
  }
  25% {
    inset: 35px 35px 0 0;
  }
  37.5% {
    inset: 35px 0 0 0;
  }
  50% {
    inset: 35px 0 0 35px;
  }
  62.5% {
    inset: 0 0 0 35px;
  }
  75% {
    inset: 0 0 35px 35px;
  }
  87.5% {
    inset: 0 0 35px 0;
  }
  100% {
    inset: 0 35px 35px 0;
  }
}

.btn--container {
  display: flex;

  .btn {
    margin-right: 20px;
  }
}

.splitter {
  height: 20px;
  padding: 0 0.5px;
  background-color: white;
  margin: 0 8px;
}

.footer__social {
  color: white;
  font-size: 18px;
}

/*

COOKIES BANNER

*/

.cookie--banner {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: map.get($map: $colors, $key: white);
  z-index: 200;
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 6px;
  width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.34);

  animation: cookieFadeIn 1000ms ease;
  animation-delay: 2000ms;
  animation-fill-mode: forwards;
  transform: translateX(100%);
  opacity: 0;
}

@keyframes cookieFadeIn {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.cookie--top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
  margin-bottom: 10px;

  a {
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    color: map.get($colors, primary-1);

    &:hover {
      text-decoration: underline;
    }
  }
}

.cookie__message {
  font-size: 14px;
  line-height: 22px;
}

.cookie--buttons {
  .btn {
    width: 100%;
  }
}

.cookie--setting {
  display: flex;
  margin-top: 30px;
  flex-direction: column;

  p {
    max-width: 600px;
  }
}

.cookie__setting-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
}

.cookie__setting--title {
  font-size: 20px;
  font-weight: 700;
}

.cookie__setting--always {
  font-weight: 600;
}

/*

MODAL

*/

.modal {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.497);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
}

.modal--container {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
}

.toggle-btn--container {
  display: flex;
  align-items: center;

  span {
    margin-right: 10px;
  }
}

.toggle-btn {
  height: 24px;
  width: 48px;
  border-radius: 50px;
  border: none;
  background-color: red;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align circle to the left initially */
  transition: background-color 300ms ease;
  cursor: pointer;
}

.toggle-btn-on {
  background-color: lightgreen;
}

.toggle-btn--circle {
  display: flex;
  height: 20px;
  width: 20px;
  background-color: white;
  border-radius: 50%;
  transition: transform 150ms ease; /* Smooth transition */
  transform: translateX(0); /* Default position */
}

.toggle-btn-on .toggle-btn--circle {
  transform: translateX(24px); /* Move to the right */
}

.buttons--container {
  display: flex;
  margin-top: 20px;

  button {
    margin-right: 10px;
  }
}

.background-dots {
  position: relative; // important!
  overflow: hidden;
  width: 100%;
  height: 100%;

  &::before {
    content: '';
    position: absolute;
    pointer-events: none;

    top: -5%;
    left: 0%; // use right: 0; if placing on right side
    width: 100%; // stay full width and mask with overflow
    height: 100%;
    background-image: radial-gradient(
      rgba(48, 48, 48, 0.575) 1px,
      transparent 1px
    );
    background-size: 10px 10px;
    transform: rotate(63deg);
    transform-origin: top left;
    border-radius: 35%;
  }
}

.background-dots-full {
  position: relative; // important!
  overflow: hidden;
  * {
    z-index: 2;
  }

  &::before {
    content: '';
    position: absolute;
    pointer-events: none;
    z-index: 1;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%; // stay full width and mask with overflow
    height: 100%;
    background-image: radial-gradient(
      rgba(48, 48, 48, 0.159) 1.5px,
      transparent 1.5px
    );
    background-size: 10px 10px;
    transform-origin: top left;
    opacity: 0.7;
  }
}

.background-lines {
  position: absolute;
  inset: 0;
  top: -20%;
  left: 60%;
  height: 200%;
  width: 100%;
  transform: rotate(-10deg);
  z-index: 0;
  pointer-events: none;

  background-image: repeating-linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.04) 0px,
    rgba(255, 255, 255, 0.04) 1px,
    transparent 1px,
    transparent 30px
  );
}

.small-text {
  font-size: 16px;
  height: 28px;
  padding: 5px 15px;
  border-radius: 15px;
  border: 1px solid rgb(47, 47, 47);
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-bottom: 14px;
}

.title-para {
  font-size: 18px;
  line-height: 30px;
  margin-left: 100px;
  font-weight: 500;
  color: map.get($colors, light-gray);

  position: relative;

  b {
    color: white;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -40px;
    width: 20px;
    height: 50%;
    max-height: 60px;
    border-bottom-left-radius: 10px;
    border-left: 1px solid rgb(67, 67, 67);
    border-bottom: 1px solid rgb(67, 67, 67);
  }

  // Triangle attached to the ::before at the right
  &::after {
    content: '';
    position: absolute;
    height: 0;
    width: 0;

    // Triangle is pointing right
    border-top: 6px solid transparent;
    border-left: 8px solid rgb(67, 67, 67);
    border-bottom: 6px solid transparent;
    left: -24px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.background-grid {
  position: relative;

  background-image: repeating-linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.04) 0px,
      rgba(255, 255, 255, 0.04) 1px,
      transparent 1px,
      transparent 14px
    ),
    repeating-linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.04) 0px,
      rgba(255, 255, 255, 0.04) 1px,
      transparent 1px,
      transparent 14px
    );
}

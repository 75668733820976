@use '../Config/variables.scss' as *;
@use '../Config/mixins.scss' as *;
@use 'sass:map';

/*

HOME

*/

#landing {
  @include flexCenterVert;
  background-size: cover;
  background-color: map.get($colors, dark-1);

  font-family: 'DM Sans', sans-serif;

  .row {
    max-width: 1600px;
  }

  .left {
    border-top: 1px solid rgb(47, 47, 47);
    border-left: 1px solid rgb(47, 47, 47);
    padding-left: 140px;
    padding-top: 70px;
    border-top-left-radius: 18px;

    position: relative;
  }

  .landing__left-text-ball-container {
    position: absolute;
    top: 0;
    right: 0;

    transform: translate(30%, -50%);
    background-color: map.get($colors, dark-1);

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 20px;
  }

  .landing__left-border-ball {
    width: 20px;
    height: 20px;
    background-color: map.get($colors, primary-1);
    border-radius: 100%;
  }

  .landing__left-border-ball-text {
    font-size: 18px;
    color: map.get($colors, white);
    font-family: 'SUSE', sans-serif;
  }
}

.boat-vector {
  position: absolute;

  right: -5%;
  bottom: 60px;

  animation: FloatingBoatVector 2s ease-in-out infinite alternate-reverse;

  path:nth-last-child(1) {
    fill: map.get($colors, primary-1);
  }

  path:nth-last-child(2) {
    fill: map.get($colors, white);
  }
}

@keyframes FloatingBoatVector {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, -50%);
  }
}

#landing--container {
  width: 100%;
  min-height: 100vh;
  min-height: max(100vh, 800px);
  @include flexCenterVert;
  color: map.get($colors, white);

  .row {
    display: flex;
  }
}

.landing-top-span {
  font-size: 16px;
  height: 28px;
  background-color: #1c1c1c;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  border-radius: 15px;
  border: 1px solid rgb(47, 47, 47);
  font-weight: bold;
  margin-bottom: 20px;
}

.landing--title {
  line-height: 78px;
  font-size: 70px;
}

.sub-title-landing {
  color: map.get($colors, light-gray);
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 50px;
  max-width: 540px;

  b {
    color: white;
  }
}

.landing-bottom {
  .flex-row {
    margin-top: 20px;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 50px;

    .btn {
      margin: 0;
      gap: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.landing-stat {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  font-weight: bolder;
}

.landing__stat-left {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 56px;
  border: 1px solid rgb(47, 47, 47);
  border-radius: 8px;
  font-size: 28px;
  color: map.get($colors, primary-1);
}

.landing__stat-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.landing_stat-number {
  font-weight: bold;
  font-size: 24px;
}

.landing--right {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.landing__img--wrapper {
  display: flex;
  overflow: hidden;
  border-radius: 20px;
  width: calc(40% / 4);
  height: 560px;
  position: relative;
  transition: all 600ms ease;
  margin-left: 20px;
}

.landing__img--open {
  width: 350px;
}

.landing__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.landing__img--info-box {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: white;
  display: flex;
  opacity: 0;
}

.landing__img--info-container {
  position: absolute;
  left: 100px;
  top: 5px;
  width: 400px;
  opacity: 0;
  transition: all 600ms ease;
  transition-delay: 100ms;
}

.landing__img--info-title {
  font-size: 18px;
  font-weight: 600;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.363);
}

.landing__img--info-text {
  font-size: 16px;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.363);
}

.landing__img--open {
  .landing__img--info-box {
    opacity: 1;
  }

  .landing__img--info-container {
    opacity: 1;
    left: 66px;
  }
}

.landing__img--info-icon {
  height: 56px;
  width: 56px;
  background-color: #00000099;
  padding: 14px;
  border-radius: 10px;
  margin-right: 10px;
}

/*

ABOUT

*/
#aboutsec {
  background-color: map.get($colors, dark-1);
  color: map.get($colors, light-gray);
  padding: 40px 0;

  .row {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .title,
  b,
  span {
    color: white;
  }

  .card-1 {
    position: relative;

    background-image: repeating-linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.08) 0px,
        rgba(255, 255, 255, 0.08) 1px,
        transparent 1px,
        transparent 10px
      ),
      repeating-linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.04) 0px,
        rgba(255, 255, 255, 0.04) 1px,
        transparent 1px,
        transparent 10px
      );

    &::before {
      content: '';
      position: absolute;
      pointer-events: none;
      z-index: 1;
      background-color: map.get($colors, primary-4);

      bottom: -5%;
      right: -8%;
      width: 100px; // stay full width and mask with overflow
      height: 100px;
      border-radius: 100%;
      filter: blur(22px);
      box-shadow: map.get($colors, primary-3) 0px 0px 10px;
    }
  }

  .card-2 {
    position: relative; // important!
    overflow: hidden;
    z-index: 2;

    &::before {
      content: '';
      position: absolute;
      pointer-events: none;
      z-index: 1;
      background-color: map.get($colors, primary-2);

      top: 0;
      left: 0;
      width: 100%; // stay full width and mask with overflow
      height: 100%;
      background-image: radial-gradient(
        rgba(115, 30, 212, 0.301) 1px,
        transparent 1px
      );
      background-size: 10px 10px;
      transform-origin: top left;
    }
  }

  .card-3 {
    background-color: #080808;
    background-position-x: 100%;
    background-size: contain;
    background-repeat: no-repeat;

    h1 {
      font-family: 'SUSE', sans-serif;
      font-size: 28px;
      font-weight: bold;
    }

    a {
      color: white;
      text-decoration: none;
      display: flex;
      align-items: center;
      max-width: none;
      gap: 8px;
      transition: all 300ms ease;

      svg {
        transform: rotate(-45deg);
        transition: all 300ms ease;
        color: white;
      }

      &:hover {
        color: map.get($colors, primary-1);

        svg {
          color: map.get($colors, primary-1);
          transform: rotate(0);
        }
      }
    }
  }
}

.aboutsec-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.aboutsec__top-left {
  display: flex;
  align-items: start;
  flex-direction: column;
  width: 50%;

  .title-para {
    margin-left: 100px;
  }
}

.aboutsec__middle {
  display: grid;
  width: 100%;
  margin-left: 110px;
  grid-template-columns: 306px 306px 2fr;
  gap: 20px; // optional spacing
  margin-top: 60px; // optional spacing
}

.aboutsec__card {
  width: 100%;
  height: 280px;
  overflow: hidden;
  border-radius: 20px;
  background-color: map.get($colors, dark-2);

  color: white;
  padding: 40px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  h1 {
    margin: 0;
    margin-bottom: 4px;
    z-index: 2;
  }

  p {
    font-size: 16px;
    font-weight: bold;
    max-width: 150px;
    z-index: 2;
  }
}

.info-box {
  background-color: map.get($colors, dark-3);
  width: 100%;
  max-width: 760px;
  height: 80px;
  display: flex;
  margin: 0 auto;
  margin-top: 60px;
  padding: 16px 15px 16px 40px;
  justify-content: space-between;
  align-items: center;
  border-radius: 100px;

  h6 {
    color: white;
    font-size: 16px;
  }

  .btn {
    background-color: white;
    color: black;
    border-radius: 100px;
    margin: 0;
    height: 48px;

    &:hover {
      background-color: map.get($colors, primary-1);
      color: white;
    }
  }
}

.hover-card:has(.aboutsec__card) {
  transition: all 100ms ease;
}

/*

SERVICE

*/

#services {
  background-color: map.get($colors, alternative-bg-dark);
  color: map-get($colors, light-gray);

  .title,
  span {
    color: white;
  }

  .title-para {
    max-width: 400px;
  }

  .info-box {
    .btn {
      background-color: map.get($colors, primary-1);
      color: white;

      &:hover {
        background-color: white;
        color: black;
      }
    }
  }
}

.services--container {
  margin-top: 60px;
  width: 100%;
}

.service {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
  padding: 44px 0;
  height: 260px;
  border-bottom: 1px solid rgb(47, 47, 47);

  &:nth-child(1) {
    border-top: 1px solid rgb(47, 47, 47);
  }

  .btn {
    border-radius: 50px;
    height: 40px;
    background: transparent;
    border: 1px solid rgb(47, 47, 47);

    &:hover {
      background-color: map.get($colors, primary-1);
    }
  }
}

.service__icon-container {
  display: flex;
  color: white;
  gap: 34px;

  text-decoration: none;

  svg {
    font-size: 60px;
  }

  &:hover {
    color: map.get($colors, primary-1);
  }
}

.service--title {
  font-family: 'SUSE', sans-serif;
  font-size: 28px;
  max-width: 250px;
}

.service--text {
  max-width: 428px;
  font-size: 18px;
  margin-bottom: 24px;
}

.service__points {
  list-style-type: none;
  color: white;
  font-weight: bold;
  font-size: 16px;
}

.service--point {
  margin-bottom: 20px;
}

/*

WORKING STEPS

*/
#workingsteps {
  background-color: map.get($colors, dark-1);
  color: white;

  .row {
    height: max(110dvh, 1000px);
    max-height: 1100px;

    max-width: none;
    padding: 0;
  }

  .title {
    text-align: start;
    color: white;
  }

  .small-title {
    position: relative;
    font-size: 34px;
    margin-bottom: 50px;
  }

  .working__steps-line {
    position: absolute;
    width: 40%;
    height: 2px;
    top: -40px;
    background-color: rgb(45, 45, 45);

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
      border-left: 6px solid rgb(45, 45, 45);
    }

    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
      border-left: 6px solid rgb(45, 45, 45);
    }
  }

  .working__founder {
    font-size: 22px;
    margin-bottom: 6px;
  }

  .sub-title {
    font-size: 18px;
    color: map.get($colors, light-gray);
  }

  .title-para {
    text-align: start;
    max-width: 300px;
  }

  .left {
    width: 60%;
    padding: 110px 50px;
    padding-left: 8vw;
    position: relative;
    overflow: hidden;
    justify-content: start;
    align-items: start;
  }

  .right {
    position: relative;
    width: 40%;
    padding: 110px 34px;
    background-color: black;

    * {
      z-index: 2;
    }

    .quote-svg {
      position: absolute;
      top: 90px;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 136px;
        height: 152px;
        fill: rgba(255, 255, 255, 0.135);
      }
    }

    &::after {
      content: '';
      position: absolute;
      right: 20%;
      top: 0;
      bottom: 0;
      width: 50px;
      background-color: map.get($colors, primary-1);
      z-index: 0;

      filter: brightness(0.3);

      border-left: 12px solid map.get($colors, primary-2);
    }
  }
}

.working__steps--container {
  position: relative;
  width: 100%;
  height: 100%;

  .hover-card {
    position: absolute;
    width: 40%;
    height: 280px;
    transition: all 100ms ease;

    .step--card {
      border-radius: 20px;
      background-color: map.get($colors, primary-1);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 24px;
      height: 100%;
      width: 100%;
    }

    &:nth-child(2) {
      right: 0;
      top: 60px;

      .step--card {
        background-color: map.get($colors, primary-2);
        flex-direction: column-reverse;
        align-items: flex-end;
        text-align: right;
      }

      .step--title {
        max-width: 200px;
      }
    }

    &:last-child {
      top: 400px;
      left: 9%;

      .step--card {
        background-color: map.get($colors, primary-3);
      }
    }
  }
}

.step__number-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: min-content;
  border-radius: 20px;
  height: 32px;
  padding: 8px 10px;
  background-color: map.get($colors, dark-3);
  gap: 6px;
  font-weight: bold;

  span {
    &:first-child {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      color: black;
      height: 16px;
      padding: 2px 6px;
      font-size: 12px;
      border-radius: 20px;
    }

    &:last-child {
      font-family: 'SUSE', sans-serif;
      font-size: 18px;
    }
  }
}

.step--title {
  font-family: 'SUSE', sans-serif;
  font-size: 28px;
  max-width: 50%;
}

/*

DESIGNS

*/
#designs {
  background-color: map.get($colors, dark-2);

  color: map.get($colors, light-gray);

  .title {
    text-align: start;
    color: white;
  }

  .title-para {
    text-align: start;
    max-width: 300px;
  }

  .row {
    max-width: 1400px;
  }

  .section-line {
    display: flex;
    margin: 0 auto;
  }
}

.designs--container {
  margin-top: 40px;
  padding: 0 40px;
  width: 100%;
}

.design--wrapper {
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.design {
  width: 100%;
  max-width: 330px;
  min-width: 250px;
  background-color: rgb(45, 45, 45);
  padding: 12px;
  position: relative;
  border-radius: 4px;
}

.design__img--wrapper {
  width: 100%;
  border-radius: 5px;
  max-height: 500px;
  //box-shadow: 0px 0px 34px 6px #a541f17c;
  cursor: s-resize;

  .design__img {
    width: 100%;
    object-fit: cover;
    object-position: top;
    height: 500px;
    transition: object-position 2s ease;

    &:hover {
      object-position: bottom;
    }
  }
}

.design--title {
  position: absolute;
  background-color: map.get($colors, primary-1);
  top: -26px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  padding: 16px;
  height: 32px;
  @include flexCentered;
  border-radius: 4px;
  color: white;
}

/*

TESTIMONIALS

*/
#testimonials {
  margin: 80px 0;
  background-color: map.get($colors, dark-1);
  margin: 0;
  padding: 110px 0;
  color: white;

  .title-para {
    max-width: 400px;
    margin-bottom: 60px;
  }

  .row {
    display: flex;
    flex-direction: column;
  }

  .section-line {
    margin-bottom: 60px;
  }
}

.testimonials--container {
  width: 100%;
}

.testimonial {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  padding: 0 36px;
}

.testimonial__pfp--container {
  width: 400px;
  height: 400px;
  border-radius: 500px 0 500px 500px;
  overflow: hidden;
  border: 1px solid rgba(211, 211, 211, 0.217);

  img {
    height: 100%;
  }
}

.testimonial--right {
  width: calc(100% - 452px);
  width: calc(100% - 452px);
  display: flex;
  flex-direction: column;
  justify-self: flex-start;
  align-items: flex-start;
  height: 400px;
  padding: 20px 0;
  padding-left: 40px;
}

.testimonial__para {
  font-size: 26px;
  margin-bottom: 40px;
  line-height: 140%;
  color: map.get($colors, light-gray);
}

.testimonial__name {
  font-size: 40px;
  font-weight: bold;
}

.testimonial__job {
  font-size: 26px;
  color: map.get($colors, light-gray);
}

.testimonial_prev,
.testimonial_next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;

  .btn {
    padding: 8px 12px;
    height: 40px;
  }
}

.testimonial_prev {
  left: 12px;
}

.testimonial_next {
  right: 12px;
}

.testimonial__btn-text {
  display: none;
}

@use '../Config/variables.scss' as *;
@use 'sass:map';

@media (max-width: 1500px) {
  #landing {
    .left {
      padding-left: 60px;
      padding-top: 0px;
    }
  }

  #workingsteps {
    .left {
      padding-left: 12px;
    }
  }
}

@media (max-width: 1450px) {
  .aboutsec__middle {
    margin-left: 0;
  }
}

@media (max-width: 1350px) {
  .landing__img--wrapper {
    width: calc(40% / 5);
    margin-left: 16px;
  }

  .landing__img--open {
    width: 300px;
  }
}

@media (max-width: 1300px) {
  .testimonial__pfp--container {
    width: 300px;
    height: 300px;
  }

  .testimonial--right {
    height: 300px;
  }

  .testimonial__para {
    font-size: 20px;
  }

  .testimonial__name {
    font-size: 26px;
  }

  .testimonial__job {
    font-size: 20px;
  }

  .testimonial {
    padding: 0;
  }
}

@media (max-width: 1200px) {
  .designs--container {
    width: auto;
    max-width: 100vw;
    justify-content: start;
    align-items: start;
  }

  .design {
    width: 330px;
  }

  .working__steps--container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .hover-card:has(.step--card) {
      position: relative;
      top: auto;
      left: auto;
      width: 48%;
    }
  }
}

@media (max-width: 1050px) {
  #landing {
    min-height: 100vh;

    .row {
      flex-direction: column;
    }

    .left,
    .right {
      width: 100%;
    }

    .left {
      margin-top: 80px;
      margin-bottom: 40px;
      padding-top: 80px;
    }

    .landing__left-text-ball-container {
      transform: translate(0%, -50%);
    }

    .boat-vector {
      right: 0;
    }

    .landing--right {
      justify-content: center;
      padding-bottom: 40px;
    }
  }

  .landing__img--wrapper {
    width: calc(40% / 4);
    margin-left: 20px;

    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .landing__img--open {
    width: 350px;
  }

  .aboutsec-top {
    .title-para {
      margin: 0;
    }
  }

  .aboutsec__middle {
    grid-template-columns: calc(90% / 2) calc(90% / 2);
    justify-content: space-evenly;
    gap: 20px;
    margin-left: 0;
  }

  .service {
    flex-direction: column;
    height: auto;
  }

  .service__icon-container {
    width: 100%;
    gap: 20px;
    margin-bottom: 20px;

    svg {
      font-size: 68px;
    }
  }

  .service--text {
    margin-bottom: 20px;
  }

  .service--middle {
    margin-bottom: 10px;
  }

  #workingsteps {
    .row {
      flex-direction: column;
      padding: 0;

      .left,
      .right {
        width: 100%;
        padding: 110px 12px;
      }

      .hover-card:has(.step--card) {
        margin-bottom: 40px;
      }
    }
  }
}

@media (max-width: 850px) {
  #landing {
    .left {
      margin-top: 80px !important;
      margin-bottom: 40px !important;
      padding-top: 40px !important;
      padding-left: 40px !important;
    }

    .landing--right {
      padding-bottom: 40px !important;
    }
  }

  .testimonials--container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .testimonial {
    flex-direction: column;
    width: 100%;
    max-width: 450px;
    background-color: map.get($colors, dark-2);
    border-radius: 8px;
    padding: 16px;
  }

  .testimonial__pfp--container {
    width: 100%;
    height: 400px;
    border-radius: 4px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .testimonial--right {
    height: auto;
    padding: 0;
    margin: 0;
    width: 100%;
    margin-top: 14px;
  }

  .testimonial__para {
    margin-bottom: 16px;
  }

  .landing--title {
    font-size: 40px;
  }

  .testimonial_prev,
  .testimonial_next {
    transform: none;
    top: 190px;
  }

  .testimonial_prev {
    left: 0;
  }

  .testimonial_next {
    right: 0;
  }

  .landing-bottom {
    .flex-row {
      gap: 30px;
    }
  }

  #workingsteps {
    .left,
    .right {
      width: 100%;
      padding: 110px 12px !important;
    }
  }
}

@media (max-width: 700px) {
  #workingsteps {
    .hover-card:has(.step--card) {
      width: 100%;
      height: 220px;
    }
  }

  .boat-vector {
    display: none;
  }

  #landing {
    .left {
      border-left: none;
      padding-left: 0 !important;
      border-radius: 0;
    }
  }

  .aboutsec-top {
    display: flex;
    flex-direction: column;

    .aboutsec__top-left,
    .aboutsec__top-right {
      width: 100%;
    }
  }

  .aboutsec__middle {
    grid-template-columns: 1fr;
  }

  .info-box {
    h6 {
      max-width: 50%;
    }

    .btn {
      width: max-content;
    }
  }

  #services {
    .title-para {
      margin-left: 0;
    }
  }

  .designs--text {
    margin-left: 0;
  }
}

@media (max-width: 500px) {
  .testimonial__para {
    font-size: 16px;
  }

  .testimonial__name {
    font-size: 22px;
  }

  .testimonial__job {
    font-size: 16px;
  }

  .info-box {
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 16px 40px;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 20px;

    h6 {
      max-width: 250px;
      margin-bottom: 20px;
    }

    .btn {
      width: max-content;
      padding: 2px 18px;
    }
  }

  .service {
    .service__icon-container {
      align-items: center;

      gap: 10px;

      svg {
        font-size: 56px;
      }
    }

    .service--title {
      font-size: 22px;
      max-width: 200px;
    }

    &:nth-child(4) {
      .service__icon-container {
        svg {
          font-size: 42px;
          margin-right: 8px;
        }
      }
    }
  }

  .step--title {
    max-width: 100%;
    width: 100%;
  }
}

@media (max-width: 420px) {
  #landing {
    text-align: center;

    .left {
      align-items: center;
    }
  }

  .landing__stat-right,
  .landing__left-text-ball-container {
    text-align: left;
  }

  .landing--right {
    display: none;
  }

  #landing--container {
    min-height: max(100dvh, 500px);
  }
}
